import "../styles/global.scss"

import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"

import Hero from "../components/hero"
import Blurb from "../components/blurb"
import About from "../components/about"
import Developers from "../components/developers"
import Contact from "../components/contact"
import Footer from "../components/footer"

const IndexPage: React.FC<PageProps> = () => {
  return (
    <main>
      <Hero />
      <Blurb />
      <About />
      <Developers />
      <Contact />
      <Footer />
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Consigma Estates Ltd</title>
