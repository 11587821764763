import React from "react";
import { Link } from "gatsby";

const heroStyles = {
  backgroundImage: "url(/images/hero-bg.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
}

const blurbContainerStyles = {
  backgroundColor: "rgba(30,30,30,0.7)",
  // padding: "6rem",
  borderRadius: "25px",
}

const Hero: React.FC = () => {
  return (
    <section className="hero is-fullheight is-primary" style={heroStyles}>
      <div className="hero-body">
        <section className="section is-mediumx" style={blurbContainerStyles}>
          <div className="container content is-max-desktop has-text-light">
            <p>
              Welcome to Consigma Estates, your trusted partner in land and property promotion through the UK planning system. We specialise in identifying and unlocking the full potential of land and properties to help you maximise your returns.
            </p>
            <p>
              With decades of experience in the industry, we have a deep understanding of the UK planning system and the complexities that come with it. We offer a wide range of services to help you navigate the planning process, from initial site assessments and feasibility studies to submitting planning applications and negotiating with local authorities.
            </p>
            <p>
              At Consigma Estates, we believe that every project is unique, which is why we take a tailored approach to every client. We work closely with you to understand your specific goals and challenges, and we develop a customised strategy to ensure you achieve the best possible outcome.
            </p>
            <p>
              Our team consists of experts in planning, development, design, and construction, so you can trust that your project is in good hands. We pride ourselves on our attention to detail, our commitment to excellence, and our ability to deliver results on time and on budget.
            </p>
            <p>
              Whether you're a landowner, developer, or investor, Consigma Estates is here to help you achieve success in the UK property market. Contact us today to discuss your project and learn how we can help you reach your goals.
            </p>
            <p className="has-text-centered mt-6">
              <Link to="/contact" className="button is-medium is-link">
                GET IN TOUCH NOW
              </Link>
            </p>
          </div>
        </section>
      </div>
    </section>
  )
};

export default Hero;
